import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";

export const query = graphql`
  query getSinglePortfolioItem($slug: String) {
    contentfulPortfolioItem(slug: { eq: $slug }) {
      title
      subtitle
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(placeholder: BLURRED)
            __typename
          }
          url
          mimeType
        }
      }
    }
  }
`;

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description, mimeType, url } = node.data.target;
      return (
        <figure className="image-caption-wrapper">
          {mimeType === "video/mp4" ? (
            <div className="image-wrapper">
              <video
                muted
                controls
                autoPlay
                loop
                style={{ width: `100%`, display: "block" }}
              >
                <source src={url} />
              </video>
            </div>
          ) : (
            <div className="image-wrapper">
              <div className="image-decorator"></div>
              <GatsbyImage
                image={getImage(gatsbyImageData)}
                alt={description}
                className="mb-10"
              />
            </div>
          )}
          <figcaption className="image-caption">{description}</figcaption>
        </figure>
      );
    },
  },
};

const PortfolioDetailPage = ({ data }) => {
  const { title, content, subtitle } = data.contentfulPortfolioItem;

  return (
    <Layout pageTitle={title}>
      <div className="portfolio-detail main-section">
        <div className="main-section-inner text-container">
          <h2 className="portfolio-detail-title">{title}</h2>
          <h3 className="portfolio-detail-subtitle">{subtitle}</h3>

          <div className="rich-text">
            {renderRichText(content, richTextOptions)}
          </div>

          <p className="portfolio-back-wrapper">
            <Link className="back-to-all" to="/portfolio">
              Back to all projects
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PortfolioDetailPage;
